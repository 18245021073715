import Background from '../assets/backgrounds/background.png';
import Ticket from '../assets/ticket_small.png';
import TicketSFT from '../assets/ticket_sft.jpg';
import Logo from '../assets/logo_small.png';
import ArtDrop from '../assets/images/Aetheris.jpg';

export const getArtDrop = () => ArtDrop;

export const getBackground = () => Background;

export const getFullTicket = () => Ticket;
export const getTicketSFT = () => TicketSFT;
export const getSmallLogo = () => Logo;
